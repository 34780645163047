import { Prize } from "../features/prize/PrizeTypes";
import giftIconA from "../assets/gift_1.png";
import giftIconB from "../assets/gift_2.png";
import giftIconC from "../assets/gift_3.png";
import prizeImgA from "../assets/glasses.png";
import prizeImgB from "../assets/fireworks.png";

const prizeDummyA: Prize = {
  id: "prize-a",
  name: "Glasses",
  image: prizeImgA,
  hiddenIcon: giftIconA,
};

const prizeDummyB: Prize = {
  id: "prize-b",
  name: "Wild Card",
  image: prizeImgB,
  hiddenIcon: giftIconB,
  description: "1 (one) request for any (reasonable) prize you want",
};

const prizeDummyC: Prize = {
  id: "prize-c",
  name: "Glasses",
  image: prizeImgA,
  hiddenIcon: giftIconC,
};

export { prizeDummyA, prizeDummyB, prizeDummyC };
